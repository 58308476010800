/*
 * @Author: reiner850593913 103234074+reiner850593913@users.noreply.github.com
 * @Date: 2022-04-08 14:54:07
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-10-30 12:06:46
 * @FilePath: \robot-management-web\src\router\index.js
 * @Description: 路由入口
 */
import { createWebHistory, createRouter } from "vue-router";
import Layout from "@/layout/PageLayout.vue";
import patroling from "./modules/patroling.js";
import admin from "./modules/admin.js";
import inspection from "./modules/inspection.js";
import disinfect from "./modules/disinfect.js";
import quadruped from "./modules/quadruped.js";
import sphere from "./modules/sphere.js";
import agv from "./modules/agv.js";

export const constRoutes = [
  {
    path: "/redirect/:pathMatch(.*)*",
    name: "redirect",
    component: () => import("@/views/RedirectPage.vue"),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: Layout,
    meta: {
      icon: "House",
      title: "首页",
      affix: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/ticket",
    name: "foot-form-ticket",
    component: () => import("@/views/quadruped/components/FormTicket.vue"),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/ticket/:key",
    name: "foot-form-create",
    component: () => import("@/views/quadruped/components/FormCreate.vue"),
    meta: {
      title: "用户表单填写",
      hidden: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/404Page.vue"),
    meta: {
      hidden: true,
    },
  },
  {
    path: "/inspection/voice-intercom",
    name: "inspection-voice-intercom",
    component: () => import("@/views/inspection/VoiceIntercom.vue"),
    meta: { title: "语音对讲", hidden: true },
  },
];

export const asyncRoutes = [
  admin,
  patroling,
  agv,
  inspection,
  disinfect,
  sphere,
  quadruped,
];

const router = createRouter({
  history: createWebHistory(),
  routes: constRoutes,
});

export function resetRouter() {
  asyncRoutes.forEach((route) => {
    router.removeRoute(route.name);
  });
}

export default router;
